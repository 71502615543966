<template>
    <div class="content-body">
        <div class="container-fluid">
<!--            <div class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">-->
<!--                <h2 class="font-w600 title mb-2 mr-auto ">Dashboard</h2>-->
<!--            </div>-->
            <div class="row">
                <div class="col-xl-4 col-sm-6 m-t35">
                    <div class="card card-coin">
                        <div class="card-body text-center">
                            <img src="../../assets/images/svg/deposit.svg" alt="deposit image" class="mb-3 currency-icon" width="80" height="80">
                            <h2 class="text-black mb-2 font-w600">${{depositAmount}}</h2>
                            <p class="mb-0 fs-14">
                                <span class="text-success mr-1">{{deposits.length}}</span>Deposits
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 m-t35">
                    <div class="card card-coin">
                        <div class="card-body text-center">
                            <img src="../../assets/images/svg/withdrawal.svg" alt="withdrawal image" class="mb-3 currency-icon" width="80" height="80">
                            <h2 class="text-black mb-2 font-w600">${{withdrawalAmount}}</h2>
                            <p class="mb-0 fs-13">
                                <span class="text-success mr-1">{{withdrawals.length}}</span>Withdrawals
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 m-t35">
                    <div class="card card-coin">
                        <div class="card-body text-center">
                            <img src="../../assets/images/svg/transfer.svg" alt="transfer image" class="mb-3 currency-icon" width="80" height="80">
                            <h2 class="text-black mb-2 font-w600">${{transferAmount}}</h2>
                            <p class="mb-0 fs-14">
                                <span class="text-success mr-1">{{transfers.length}}</span>Transfers
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mt-2">
                <div class="col-xl-4 col-sm-6 m-t35">
                    <div class="card card-coin">
                        <div class="card-body text-center">
                            <img src="../../assets/images/svg/next-earning.svg" alt="deposit image" class="mb-3 currency-icon" width="80" height="80">
                            <h2 class="text-black mb-2 font-w600">${{depositEarning}}</h2>
                            <p class="mb-0 fs-14">
                                <span class="text-success mr-1"><i class="fa fa-money"></i></span>Next Interests
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 m-t35">
                    <div class="card card-coin">
                        <div class="card-body text-center">
                            <img src="../../assets/images/svg/accumulated-earning.svg" alt="withdrawal image" class="mb-3 currency-icon" width="80" height="80">
                            <h2 class="text-black mb-2 font-w600">${{depositEarnings}}</h2>
                            <p class="mb-0 fs-13">
                                <span class="text-success mr-1"><i class="fa fa-money-bill"></i></span>Accumulated Earnings
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-9 col-xxl-8">
                    <div class="card">
                        <div class="card-header border-0 flex-wrap pb-0">
                            <div class="mb-3">
                                <h4 class="fs-20 text-black">Market Overview</h4>
<!--                                <p class="mb-0 fs-12 text-black">Lorem ipsum dolor sit amet, consectetur</p>-->
                            </div>
                        </div>
                        <div class="card-body pt-0 pb-2 px-3">
                            <div style="height:560px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: none; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; padding:1px;padding: 0px; margin: 0px; width: 100%;">
                                <div style="height:540px; padding:0px; margin:0px; width: 100%;">
                                    <iframe src="https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=859&pref_coin_id=1505" width="100%" height="536px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;line-height:14px;"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-xxl-4">
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h4 class="fs-20 text-black">Recent Transfers</h4>
                        </div>
                        <div class="card-body pb-0">
                            <div id="currentChart" class="current-chart"></div>
                            <div class="chart-content">
                                <div class="d-flex justify-content-between mb-2 align-items-center" v-for="(transfer, idx) in transfers" :key="'transfer'+idx">
                                    <template v-if="idx <= 20">
                                        <div>
                                            <svg class="mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="15" height="15" rx="7.5" fill="#EB8153"/>
                                            </svg>
                                            <span class="fs-14">{{getReadableTime(transfer.data.createdAt)}}</span>
                                        </div>
                                        <div>
                                            <h5 class="mb-0">${{transfer.data.amount.toLocaleString()}}</h5>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import methodsMixin from "../../utils/methodsMixin";

export default {
    name: "dashboard",
    mixins: [methodsMixin],
    computed: {
        ...mapGetters({
            deposits: 'deposit/getDeposits',
            withdrawals: 'withdrawal/getWithdrawals',
            transfers: 'transfer/getTransfers'
        }),
        depositAmount(){
            return Number(this.deposits.reduce((prev, curr)=>prev+curr.data.amountDeposited, 0)).toLocaleString()
        },
        depositEarnings(){
            return Number(this.deposits
                .filter(deposit=>deposit.data.status==='FILLED')
                .reduce((prev, curr)=>prev+curr.data.amountEarned, 0)).toLocaleString()
        },
        depositEarning(){
            return Number(this.deposits
                .filter(deposit=>deposit.data.status==='IN-PROGRESS')
                .reduce((prev, curr)=>prev+curr.data.amountEarned, 0)).toLocaleString()
        },
        withdrawalAmount(){
            return Number(this.withdrawals.reduce((prev, curr)=>prev+curr.data.amount, 0)).toLocaleString()
        },
        transferAmount(){
            return Number(this.withdrawals.reduce((prev, curr)=>prev+curr.data.amount, 0)).toLocaleString()
        }
    },
    mounted() {
        this.$store.dispatch('deposit/getDeposits');
        this.$store.dispatch('withdrawal/fetchWithdrawals');
        this.$store.dispatch('transfer/fetchTransfers')
    }
}
</script>

<style scoped>

</style>